<script setup lang="ts">
declare const window: any;

import {timeAgo, time} from '../helpers';


// Import Swiper Vue.js components
import {Swiper, SwiperSlide} from 'swiper/vue';
//import { Navigation, Pagination, Scrollbar, A11y} from 'swiper'
import {Navigation, Pagination, Scrollbar, A11y} from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const onSwiper = (swiper) => {

};

const onSlideChange = () => {

};

const moreArticles = async (id: string) => {
    console.log(id)
    for (let i = 0;i < Object.keys(filterArticles.value).length; i++) {
        if (filterArticles.value[i].id === id) {
            const articles: any = await loadArticlesData({position: position.value + 8, slug: {slug: id}})
            filterArticles.value[i].articles = filterArticles.value[i].articles.concat(articles)
        }
    }

}

const modules = [Navigation, Pagination, Scrollbar, A11y]


useHead({
    titleTemplate: 'The Political Room - Medio de comunicación',
    bodyAttrs: {
        class: `home-template global-hash-load-more`
    },
});

interface ICover {
    url: string;
}

interface IArticle {
    id: number;
    title: string;
    excerpt?: string;
    cover?: ICover[];

}

interface IArticles {
    id: string;
    articles: IArticle[];
}

const localePath = useLocalePath();
const {locale} = useI18n();

const totalArticles = ref(0);
const articlesData = ref([] as any);
const featuredArticlesData = ref([] as any);
const isReady = ref(false);
const position = ref(0);
const filterArticles = ref([] as any)

const loadArticlesData = async ({position = 0, featured = false, slug = {}}: any): Promise<any> => {
    const {articles} = await $fetch(`/api/strapi/articles`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            locale: locale.value,
            position,
            featured,
            slug
        })
    });
    return articles
}

const loadMoreArticles = async (): Promise<any> => {
    position.value = position.value + 16;
    const articles: any = await loadArticlesData({position: position.value});
    articlesData.value = articlesData.value.concat(articles);
}


onMounted(async () => {
    const categories = ['politica', 'defensa-1', 'economia', 'opinion', 'entrevistas']
    const articles: any = await loadArticlesData({position: position.value});
    const cat_filter: any = [
        {
            id: categories[0],
            articles: await loadArticlesData({position: position.value, slug: {slug: categories[0]}})
        },
        {
            id: categories[1],
            articles: await loadArticlesData({position: position.value, slug: {slug: categories[1]}})
        },
        {
            id: categories[2],
            articles: await loadArticlesData({position: position.value, slug: {slug: categories[2]}})
        },
        {
            id: categories[3],
            articles: await loadArticlesData({position: position.value, slug: {slug: categories[3]}})
        },
        {
            id: categories[3],
            articles: await loadArticlesData({position: position.value, slug: {slug: categories[4]}})
        }
    ]
    filterArticles.value = cat_filter
    totalArticles.value = articles.length;
    articlesData.value = articles;
    featuredArticlesData.value = articles.filter((article: any) => article.featured).slice(0, 10);

    if (articlesData.value) {
        isReady.value = true;
    }


    /*setTimeout(() => {
        /!* Custom settings for Flickity *!/
        var slider = document.getElementById('slider'),
            sliderTrigger = document.querySelector('.section-scrollable .section-featured');

        if (sliderTrigger) {

            var flkty = new window.Flickity(slider, {
                wrapAround: true,
                contain: true,
                adaptiveHeight: true,
                accessibility: false,
                prevNextButtons: false,
                pageDots: false,
                selectedAttraction: .025,
                friction: .3,
                dragThreshold: 5
            });

            /!* Next button *!/
            var nextButton = document.getElementById('next');
            if (nextButton) {
                nextButton.addEventListener('click', function () {
                    flkty.next();
                });
            }

            /!* Add class to "loop" when is slider *!/
            var loop = document.getElementById('loop');
            if (loop && flkty.cells.length > 0) {
                loop.classList.remove('no-featured');
                loop.classList.add('is-featured');
            }

            /!* iOS 12 & 13 fix *!/
            let tapArea;
            let startX = 0;
            tapArea = document.querySelectorAll('.section-scrollable');
            for (let item of tapArea) {
                item.ontouchstart = function (e) {
                    startX = e.touches[0].clientX;
                };
                item.ontouchmove = function (e) {
                    if (Math.abs(e.touches[0].clientX - startX) > 5 && e.cancelable) {
                        e.preventDefault();
                    }
                };
            }
        }

    }, 500);*/


});



</script>
<style scoped>
    .img-portada {

        height: 250px;
        width: 100%;
        border-radius: var(--border-radius-secondary);
        background: no-repeat center center;
        background-size: cover;
    }

    .container-portada {
        margin: 0 1rem;
    }

    .container-portada .swiper-pagination {
        /*display: none;*/
        /*margin: 2rem 0;*/
    }

    .container-portada .test-swiper {
       /* margin: 0 8rem;*/
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 2rem;
    }

    .cont-article {
        padding: 2% 0 0 2%
    }

    .featured-label {
        color: var(--color-bg-featured-label);
        background-color: var(--color-featured-label);
    }

    .featured-label svg {

        fill: var(--color-bg-featured-label);
    }

    .item-meta.white {
        margin-bottom: 3rem;
    }

    @media (min-width: 767px) {
        .container-portada {
            margin: 0 8rem;
        }
        .img-portada {

            height: 500px;
            width: 80%;
            max-width: 600px;
        }
        .container-portada .test-swiper {
            gap: unset;
            flex-direction: row;
        }
        .cont-article {
            padding: 2% 25% 0 2%;
            width: 80%
        }
        .item-meta.white {
            margin-bottom: 4rem;
        }
    }

    .btn-show-more {

        width: 106px;
        height: 36px;
        margin: 1rem;
        padding: 7px 10px;
        border-radius: 18px;
        background-color: #f0f0f0;

        &:active {
            background-color: #2b2d3255;
        }

    }







    @media (min-width: 767px) and (max-width: 1024px) {
        /*.container-portada .test-swiper {
            gap: unset;
            flex-direction: column;
        }*/
        .cont-article {
            padding: 2% 2% 0 2%;

        }
        .img-portada {

            height: 500px;
            width: 120%;
            max-width: 600px;
        }
    }

</style>

<style scoped>
    .titles-font {
        font-family: "ivypresto-display", serif;
        font-weight: 700;
        font-style: normal;
    }
</style>
<template>
    <div>

        <Head>
            <Title></Title>
            <Meta name="description" :content="'Medio de comunicación independiente'"/>
            <Html :lang="locale"/>
        </Head>

        <div v-if="isReady">

            <div class="container-portada">
                <Swiper
                    :modules="modules"
                    :slides-per-view="1"
                    :space-between="20"
                    navigation
                    :pagination="{ clickable: true }"
                    @swiper="onSwiper"
                    @slideChange="onSlideChange"
                >
                    <SwiperSlide v-for="featuredArticle in featuredArticlesData" :key="featuredArticle.id" class="test-swiper">
                        <div class="img-portada"
                             :style="{ backgroundImage: `url(${featuredArticle.cover && featuredArticle.cover[0].url})` }"></div>
                        <div class="cont-article" style=""><!--featured-wrap flex-->
                            <article class="featured-content">
                            <span class="featured-label global-tag">
                                <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="#161b3d">
                                    <path
                                        d="M22.9712403,8.05987765 L16.2291373,8.05987765 L12.796794,0.459688839 C12.5516266,-0.153229613 11.4483734,-0.153229613 11.0806223,0.459688839 L7.64827899,8.05987765 L0.906176009,8.05987765 C0.538424938,8.05987765 0.170673866,8.30504503 0.0480901758,8.6727961 C-0.0744935148,9.04054717 0.0480901758,9.40829825 0.293257557,9.65346563 L5.31918887,14.3116459 L3.11268244,22.4021694 C2.99009875,22.7699205 3.11268244,23.1376716 3.48043351,23.382839 C3.72560089,23.6280063 4.21593565,23.6280063 4.46110303,23.5054227 L11.9387082,19.2149935 L19.4163133,23.5054227 C19.538897,23.6280063 19.6614807,23.6280063 19.906648,23.6280063 C20.1518154,23.6280063 20.2743991,23.5054227 20.5195665,23.382839 C20.7647339,23.1376716 20.8873176,22.7699205 20.8873176,22.4021694 L18.6808111,14.3116459 L23.7067424,9.65346563 C23.9519098,9.40829825 24.0744935,9.04054717 23.9519098,8.6727961 C23.7067424,8.30504503 23.3389914,8.05987765 22.9712403,8.05987765 Z"></path>
                                </svg>
                                Destacado
                            </span>
                                <h2 class="">
                                    <NuxtLink class="titles-font"
                                        :to="localePath({ name: 'blog-slug', params: { slug: featuredArticle.slug } })">
                                        {{ featuredArticle.title }}
                                    </NuxtLink>
                                    <span class="featured-dot"></span>
                                </h2>
                                <div class="item-meta white">
                                    <span>Por </span>
                                    <NuxtLink
                                        :to="localePath({ name: 'colaborate-id', params: { id: featuredArticle && featuredArticle.author && featuredArticle.author.id } })"
                                        class="author-name white">
                                        {{
                                            (featuredArticle && featuredArticle.author && featuredArticle.author.firstname) + ' ' + (featuredArticle && featuredArticle.author && featuredArticle.author.lastname ? ' ' + featuredArticle.author.lastname : '')
                                        }}
                                    </NuxtLink>
                                    <div
                                        v-if="featuredArticle.other_authors && featuredArticle.other_authors.length > 0"
                                        style="display: inline">
                                        /
                                        <NuxtLink
                                            :to="localePath({ name: 'colaborate-id', params: { id: featuredArticle.other_authors[0].id } })"
                                            class="author-name white">
                                            {{ featuredArticle.other_authors[0].firstname || 'otro autor' }}
                                        </NuxtLink>
                                    </div>
                                    <br>
                                    <time >{{ time(featuredArticle.publishedAt) }}</time>
                                </div>
                            </article>
                        </div>

                    </SwiperSlide>
                    ...
                </Swiper>
            </div>

            <!--            <div id="slider" class="section-scrollable">

                            <div class="section-featured is-featured-image" v-for="featuredArticle in featuredArticlesData"
                                 :key="featuredArticle.id">
                                ˘
                                <div class="featured-wrap flex">
                                    <article class="featured-content">
                                        <span class="featured-label global-tag">
                                            <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M22.9712403,8.05987765 L16.2291373,8.05987765 L12.796794,0.459688839 C12.5516266,-0.153229613 11.4483734,-0.153229613 11.0806223,0.459688839 L7.64827899,8.05987765 L0.906176009,8.05987765 C0.538424938,8.05987765 0.170673866,8.30504503 0.0480901758,8.6727961 C-0.0744935148,9.04054717 0.0480901758,9.40829825 0.293257557,9.65346563 L5.31918887,14.3116459 L3.11268244,22.4021694 C2.99009875,22.7699205 3.11268244,23.1376716 3.48043351,23.382839 C3.72560089,23.6280063 4.21593565,23.6280063 4.46110303,23.5054227 L11.9387082,19.2149935 L19.4163133,23.5054227 C19.538897,23.6280063 19.6614807,23.6280063 19.906648,23.6280063 C20.1518154,23.6280063 20.2743991,23.5054227 20.5195665,23.382839 C20.7647339,23.1376716 20.8873176,22.7699205 20.8873176,22.4021694 L18.6808111,14.3116459 L23.7067424,9.65346563 C23.9519098,9.40829825 24.0744935,9.04054717 23.9519098,8.6727961 C23.7067424,8.30504503 23.3389914,8.05987765 22.9712403,8.05987765 Z"></path>
                                            </svg>
                                            Destacado
                                        </span>
                                        <h2>
                                            <NuxtLink
                                                :to="localePath({ name: 'blog-slug', params: { slug: featuredArticle.slug } })">
                                                {{ featuredArticle.title }}
                                            </NuxtLink>
                                            <span class="featured-dot"></span>
                                        </h2>
                                        <div class="item-meta white">
                                            <span>por </span>
                                            <NuxtLink
                                                :to="localePath({ name: 'colaborate-id', params: { id: featuredArticle && featuredArticle.author && featuredArticle.author.id } })"
                                                class="author-name white">
                                                {{
                                                    (featuredArticle && featuredArticle.author && featuredArticle.author.firstname) + ' ' + (featuredArticle && featuredArticle.author && featuredArticle.author.lastname ? ' ' + featuredArticle.author.lastname : '')
                                                }}
                                            </NuxtLink>
                                            <div v-if="featuredArticle.other_authors && featuredArticle.other_authors.length > 0"
                                                 style="display: inline">
                                                /
                                                <NuxtLink
                                                    :to="localePath({ name: 'colaborate-id', params: { id: featuredArticle.other_authors[0].id } })"
                                                    class="author-name white">
                                                    {{ featuredArticle.other_authors[0].firstname || 'otro autor' }}
                                                </NuxtLink>
                                            </div>

                                            <time>{{ time(featuredArticle.publishedAt) }}</time>
                                        </div>
                                    </article>
                                </div>
                            </div>

                        </div>-->

            <div id="next" class="scrollable-nav">
                <span class="next"></span>
            </div>


            <div class="container-slider" v-for="articles in filterArticles" :key="articles.id">
                <!--section-loop wrap is-featured--><!--:scrollbar="{ draggable: true }"-->

                <div class="items-wrap flex">

                    <div class="item-wrap flex post section-slider"> <!--item-wrap flex post is-image-->
                        <div style="display: flex;justify-content: start;width: 100%;">
                            <h3 class="titles-font" style="margin: 1rem 1rem 0 1rem;width: 100%;">{{
                                    articles.articles[0].article_categories && articles.articles[0].article_categories[0] && articles.articles[0].article_categories[0].name
                                }}</h3>
                            <div style="display: flex;justify-content: end;">
                                <NuxtLink :to="localePath({ name: 'categories-slug', params: { slug: articles.id } })"
                                          class="btn-show-more" style="display: flex;justify-content: center;align-items: center;border-radius: 100px">
                                    Ver más
                                </NuxtLink>
<!--                                <button @click="moreArticles(articles.id)" class="btn-show-more" style="border: none;cursor: pointer;">Ver más</button>-->
                            </div>
                        </div>


                        <Swiper
                            :modules="modules"
                            :slides-per-view="1"
                            :space-between="10"
                            navigation
                            :pagination="{ clickable: true }"

                            :breakpoints="{
                                768: { slidesPerView: 2 },
                                1200: { slidesPerView: 3 },
                                1919: { slidesPerView: 4 }
                            }"
                            @swiper="onSwiper"
                            @slideChange="onSlideChange"
                        >
                            <SwiperSlide v-for="article in articles.articles" :key="article.id">

                                <article style="height: 300px;margin: 0 0 15px 15px">
                                    <NuxtLink :to="localePath({ name: 'blog-slug', params: { slug: article.slug } })"
                                              class="item-link-overlay" :aria-label="article.title"></NuxtLink>
                                    <div class="item-image"
                                         :style="{ backgroundImage: `url(${article.cover && article.cover[0].url})` }"></div>
                                    <a class="secondary-tag global-tag white" href="#"
                                       v-if="article && article.premium">PREMIUM</a>
                                </article>
                                <div style="padding: 0 6%">
                                    <NuxtLink :to="localePath({ name: 'blog-slug', params: { slug: article.slug } })"
                                              class="white">
                                        <h4 class="" style="margin: 0 0 10px 0;"> {{ article.title }} </h4>
                                    </NuxtLink>
                                    <div class="item-meta white is-primary-tag is-members-label">
                                        <span>Por </span>
                                        <NuxtLink
                                            :to="localePath({ name: 'colaborate-id', params: { id: article.author.id } })"
                                            class="author-name white">
                                            {{
                                                (article && article.author && article.author.firstname) + ' ' + (article && article.author && article.author.lastname ? ' ' + article.author.lastname : '')
                                            }}
                                        </NuxtLink>
                                        <div v-if="article.other_authors && article.other_authors.length > 0"
                                             style="display: inline">
                                            /
                                            <NuxtLink
                                                :to="localePath({ name: 'colaborate-id', params: { id: article.other_authors[0].id } })"
                                                class="author-name white">
                                                {{ article.other_authors[0].firstname || 'otro autor' }}
                                            </NuxtLink>
                                        </div>
                                        <br>
                                        <time>{{ time(article.publishedAt) }}</time>
                                    </div>
                                    <!--                                    <a class="primary-tag global-tag white" href="#">{{
                                                                                article.article_categories && article.article_categories[0] && article.article_categories[0].name
                                                                            }}</a>-->
                                </div>

                            </SwiperSlide>
                            ...
                        </Swiper>
                        <div class="swiper-pagination"></div>
                        <!--                        <div class=""
                                                     :style="{ backgroundImage: `url(${article.cover && article.cover[0].url})`, height: '500px', width: '100%' }"></div>-->
                        <!--                        <article>
                                                    <NuxtLink :to="localePath({ name: 'blog-slug', params: { slug: article.slug } })"
                                                              class="item-link-overlay" :aria-label="article.title"></NuxtLink>

                                                    <div class="item-image"
                                                         :style="{ backgroundImage: `url(${article.cover && article.cover[0].url})` }"></div>

                                                    <NuxtLink :to="localePath({ name: 'blog-slug', params: { slug: article.slug } })"
                                                              class="white">
                                                        <h2> {{ article.title }} </h2>
                                                    </NuxtLink>

                                                    <div class="item-meta white is-primary-tag is-members-label">
                                                        <span>por </span>
                                                        <NuxtLink :to="localePath({ name: 'colaborate-id', params: { id: article.author.id } })"
                                                                  class="author-name white">
                                                            {{
                                                                (article && article.author && article.author.firstname) + ' ' + (article && article.author && article.author.lastname ? ' ' + article.author.lastname : '')
                                                            }}
                                                        </NuxtLink>
                                                        <div v-if="article.other_authors && article.other_authors.length > 0"
                                                             style="display: inline">
                                                            /
                                                            <NuxtLink
                                                                :to="localePath({ name: 'colaborate-id', params: { id: article.other_authors[0].id } })"
                                                                class="author-name white">
                                                                {{ article.other_authors[0].firstname || 'otro autor' }}
                                                            </NuxtLink>
                                                        </div>
                                                        <br>
                                                        <time>{{ time(article.publishedAt) }}</time>
                                                    </div>
                                                    <a class="primary-tag global-tag white" href="#">{{
                                                            article.article_categories && article.article_categories[0] && article.article_categories[0].name
                                                        }}</a>
                                                    <a class="secondary-tag global-tag white" href="#"
                                                       v-if="article && article.premium">PREMIUM</a>
                                                </article>-->
                    </div>
                </div>
            </div>

            <!--            <div class="section-load-more">
                            <div @click="loadMoreArticles()" class="load-more" style="display: inline-block">
                                <span>Cargar más</span>
                            </div>
                        </div>-->

        </div>
        <div v-else class="spinner">
            <Spinner/>
        </div>

        <SubscribeForm/>

    </div>
</template>


<style>

.container-slider {

    margin-top: 1rem;
}

.swiper-pagination-bullet {
    background: #000;
}

.swiper-button-next,
.swiper-button-prev {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.3);
    padding: 0 .5rem;
    border-radius: 100px;
    display: none;
}

.secondary-tag {
    margin-left: unset;
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
    color: var(--ghost-accent-color);
}


.swiper-button-next::after,
.swiper-button-prev::after {
    font-size: 20px;
}

.section-slider h3 {
    color: var(--ghost-accent-color);
}

.section-slider {
    /*padding: 0 14rem;*/
    border-top: 3px solid #f0f0f0;
    margin: 0 1rem;

}

@media (min-width: 767px) {
    .section-slider {
        margin: 0 8rem;
    }

    .swiper-button-next,
    .swiper-button-prev {
        display: inline-flex;
    }
}

.flex {
    justify-content: center;
}

.my-swiper {
    width: 100%;
    max-width: 600px;
    margin: auto;
}

.item-wrap article {
    border-radius: 5px;
}
</style>
